import React, { Component } from 'react';

export default class Label extends Component {
  render() {
    const {
      caption,
      style,
      colon,
      labelType,  
      block,
      ...rest
    } = this.props;

    let typeFontSize = '14px';
    let typeFontWeight = 300;
   
    switch (labelType) {
      case "H1":
        typeFontSize = '96px';
        break;
      case "H2":
        typeFontSize = '60px';
        break;
      case "H3":
        typeFontSize = '48px';
        typeFontWeight = 500;
        break;
      case "H4":
        typeFontSize = '34px';
        typeFontWeight = 500;
        break;
      case "H5":
        typeFontSize = '24px';
        typeFontWeight = 500;
        break;
      case "H6":
        typeFontSize = '20px';
        typeFontWeight = 700;
        break;
      case "H7":
        typeFontSize = '18px';
        typeFontWeight = 700;
        break;
      case "Subtitle1":
        typeFontSize = '16px';
        typeFontWeight = 500;
        break;
      case "Subtitle2":
        typeFontWeight = 700;
        break;
      case "Body1":
        typeFontSize = '16px';
        typeFontWeight = 500;
        break;
      case "Body2":
        typeFontSize = '16px';
        typeFontWeight = 400;

        break;
      case "Mini":
        typeFontSize = '12px';
        typeFontWeight = 500;
        break; 
      case "Tag":
        typeFontSize = '12px';
        typeFontWeight = 700;
        break;
      default:
    }


    return (
      
        <div style={{ altText: caption, display: block?"block":"inline-block", marginBottom: block?"4px":"0px", width: 'auto', fontSize: typeFontSize, fontWeight: typeFontWeight, ...style }}  {...rest} aria-label={caption}>
            {caption}{colon ? ":" : ""}
        </div>
       
    )
  }
}
