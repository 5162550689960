import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import MenuIcon from '@material-ui/icons/Menu';
import TermsIcon from '@material-ui/icons/CheckBox';
import AboutUsIcon from '@material-ui/icons/Help';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Label from '../Label';
import { Row, Modal, Button } from 'antd';
import companyLogo from '../Assets/logo-small.png';
import Terms from '../Components/Terms';
import AboutUs from '../Components/AboutUs';
// import MenuItem from '@material-ui/core/MenuItem';
// import Menu from '@material-ui/core/Menu';
// import MapIcon from '@material-ui/icons/LocationOn';

const useStyles = makeStyles(theme => ({
    paper: {
        position: 'absolute',
        width: 250,
        backgroundColor: theme.palette.background.paper,
        border: '1px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
    grow: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2), color: "white"
    },
    title: {
        flexGrow: 1,
        color: "white",
        textAlign: 'right',
    },
    mobiletitle: {
        flexGrow: 1,
        color: "white",
        textAlign: 'left',
    },
    titleName: {
        flexGrow: 1,
        color: "white",
        textAlign: 'left',
    },
    toolbar: {
        backgroundColor: "black",
        opacity:'.8'
// #1890ff
    },
    sectionMobile: {
        display: 'flex',
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    },
    list: {
        width: 250,
    },
    fullList: {
        width: 'auto',
    },
    nested: {
        paddingLeft: theme.spacing(4),
    },
}));

export default function MobileTopBar(props) {

    const classes = useStyles();

    const [state, setState] = React.useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
        showTerms: false,
        showAboutUs: false
    });

    const toggleDrawer = (side, open) => event => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        setState({ ...state, [side]: open });
    };

    const handleMenuClick = (menuOption) => {
        switch (menuOption) {
            case 'about':
                setState({ ...state, left: false, showAboutUs: true });
                break;
            case 'terms':
                setState({ ...state, left: false, showTerms: true });
                break;
            default:
        }
    };

    const handleCancelTerms = () => {
        setState({ ...state, showTerms: false });
    };

    const handleCancelAboutUs = () => {
        setState({ ...state, showAboutUs: false });
    };

    let TermsModal = null;
    if (state.showTerms === true)
        TermsModal = <Modal
            title={null}
            visible={state.showTerms}
            onCancel={handleCancelTerms}
            style={{top:'56px', width:'100%'}}
            footer={null}
        >
            <Terms />
            <Row style={{ float: 'right', padding: '0px', marginTop: '8px', marginRight: '24px', marginBottom: '16px', position: 'absolute', bottom: '0px', right: '0px' }}>
                <Button size='large' style={{ float: 'right' }} onClick={() => handleCancelTerms()}>Close</Button>
            </Row>
        </Modal>;

    let AboutUsModal = null;
    if (state.showAboutUs === true)
        AboutUsModal = <Modal
            title={null}
            visible={state.showAboutUs}
            onCancel={handleCancelAboutUs}
            style={{ top: '56px', width: '100%' }}
            footer={null}
        >
            <AboutUs />
            <Row style={{ float: 'right', padding: '0px', marginTop: '8px', marginRight: '24px', marginBottom: '16px', position: 'absolute', bottom: '0px', right: '0px' }}>
                <Button size='large' style={{ float: 'right' }} onClick={() => handleCancelAboutUs()}>Close</Button>
            </Row>
        </Modal>;

    const sideList = side => (
        <div
            className={classes.list}
            role="presentation"
        >
            <img src={companyLogo} alt='Langley Pos-Abilities Society' style={{ width: '100%', height: 'auto', paddingBottom: '8px' }} />
            <Divider />
            <List>
                <ListItem button key="about" onClick={() => handleMenuClick("about")} >
                    <ListItemIcon><AboutUsIcon />
                    </ListItemIcon>
                    <ListItemText primary="About Us " />
                </ListItem>

                <ListItem button key="terms" onClick={() => handleMenuClick("terms")} >
                    <ListItemIcon><TermsIcon />
                    </ListItemIcon>
                    <ListItemText primary="Terms" />
                </ListItem>

            </List>
            <Row >
                <Label type='Body2' caption={'© ' + (new Date().getFullYear()) + ' Langley Pos-Abilities Society'} style={{ padding: '8px 16px' }} />
            </Row>
        </div>
    );
   
    const renderMenu = (
        <Drawer open={state.left} onClose={toggleDrawer('left', false)} style={{ zIndex: 99999 }}>
            {sideList('left')}
        </Drawer>
    );

    const renderToolbar = (
        <Toolbar className={classes.toolbar} >
            <React.Fragment>
                <IconButton
                    edge="start"
                    className={classes.menuButton}
                    color="inherit"
                    aria-label="open drawer"
                    onClick={toggleDrawer('left', true)}
                >
                    <MenuIcon />
                </IconButton>
                <Typography className={classes.titleName} variant="h6" noWrap>
                    Map Admin
                </Typography>
            </React.Fragment>
        </Toolbar>
    );
    return (
        <div>
            <AppBar>
                {renderToolbar}
            </AppBar>
            {renderMenu}
            {TermsModal}
            {AboutUsModal}
        </div>
    );
}


