import React from "react";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../authConfig";
import {Button} from "antd";
import { useIsAuthenticated } from "@azure/msal-react";

function handleLogin(instance) {
  instance.loginPopup(loginRequest).catch(e => {
    console.error(e);
  });
}

function handleLogout(instance) {
  instance.logoutPopup().catch(e => {
    console.error(e);
  });
}

/**
 * Renders a button which, when selected, will open a popup for login
 */
export const SignInButton = () => {
  const { instance } = useMsal();
  const isAuthenticated = useIsAuthenticated();

  return (
    isAuthenticated ? <Button type="primary" onClick={() => handleLogout(instance)}>Sign Out</Button>:
      <Button type="primary" disabled={isAuthenticated} onClick={() => handleLogin(instance)}>Sign in</Button>
  );
}