import React, { Component } from 'react';
import { Modal, Button, Row, Col } from 'antd';
import logosmall from '../Assets/logo-small.png';
import logo from '../Assets/Logo-header.png';
import {SignInButton} from './SignInButton'

export class MapHeader extends Component {
  state = {
    showWindow: false,
    windowTitle: '',
  }

  openWindow = (message) => {
    this.setState({ showWindow: true, windowTitle: message, });
  }

  handleCancel = () => {
    this.setState({ showWindow: false, windowTitle: '', });
  }
  
  render() {
    const { showWindow, windowTitle } = this.state;
   
    let ModalWindow = null;
    if (showWindow === true)
      ModalWindow = <Modal
        title={windowTitle}
        visible={showWindow}
        onOk={this.handleOk}
        onCancel={this.handleCancel}
        width={600}
        footer={null}
      >
        <Row style={{ paddingBottom: '48px' }}>
          Questions about location
        </Row>

        <Row style={{ float: 'right', padding: '0px', marginTop: '8px', marginRight: '24px', marginBottom: '16px', position: 'absolute', bottom: '0px', right: '0px' }}>
          <Button size='large' type='primary' style={{ marginRight: '16px', float: 'right' }}>Save My Report</Button>
          <Button size='large' style={{ float: 'right' }} onClick={() => this.handleCancel()}>Cancel</Button>
        </Row>
      </Modal>;

    let page = null;

    if(window.innerWidth >=768)
      page = <div id='mapHeader' style={{
        position: 'absolute',
        left: '0px',
        right: '0px',
        top: '0px'
      }}>
        <Row>
          <Col span={ 8 }>
            <div style={{ style: 'block' }}><a href="https://pos-abilities.org" target='_blank' rel="noreferrer"><img src={logo} alt='Langley Pos-Abilities' style={{ height: '116px', margin: '16px' }} /></a></div>
          </Col>
          <Col span={8}  >
            <div style={{ marginTop: "80px", textAlign:'right' }}>
              <SignInButton  />
            </div>
          </Col>
          <Col span={8}  >
            <img src={logosmall} alt='Map Admin' style={{ maxHeight: '140px',float: 'right', marginRight: '16px' }} />
          </Col>
        </Row>
      </div>;
   

    if (window.innerWidth < 768)
      page = <div id='mapHeader' style={{
        position: 'absolute',
        left: '0px',
        right: '0px',
        top: '0px'
      }}>
        <Row>
          <Col span={24}>
            <img src={logosmall} alt='Access for All' style={{ maxHeight: '140px' }} />
          </Col>
          <Col span={24} style={{ textAlign: 'center', padding: '0px 16px 16px 16px'}}>
          </Col>
        </Row>
      </div>;

    return (
      <React.Fragment>
        {page}
        {ModalWindow}
      </React.Fragment>
    )
  }
}
export default MapHeader