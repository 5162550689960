import React, { Component } from 'react';
import { Modal, Button, Row, Col, Spin, Table, Popconfirm } from 'antd';
import axios from 'axios';
import Label from '../Label';
import { loginRequest } from "../authConfig";
import { PublicClientApplication } from "@azure/msal-browser";

export class WebMap extends Component {
  state = {
    showLocationModal: false,
    showReport: false,
    reportType: '',
    locationId: '',
    placeId: '',
    locationName: '',
    currentLocationId: '',
    POI: null,
    mapData: [],
    reportLocation: [],
    reportHeader: [],
    reportDetails: [],
    steps: [],
    placeTypes: [],
    bounds: null,
    currentLocation: {},
    spinning: false,
    type: '',
    status: '',
    accessToken:''
  }

  componentDidMount() {
    this.loadReportData();
  }

  componentDidUpdate(prevProps, prevState) {
  }

  getAccessToken = async() =>{
    const configuration = { auth: { clientId: "0035b328-9453-4591-a076-36736d9c7ddd" } };
    const pca = new PublicClientApplication(configuration);
    const accounts = pca.getAllAccounts();
    const request = {
      ...loginRequest,
      account: accounts[0]
    };
   
    let response =  await pca.acquireTokenSilent(request);
     
    return "Bearer " + response.accessToken
    
  }

  loadReportData = async() => {
    this.setState({ spinning: true, });
    let bearer = await this.getAccessToken();
    let reportQuery = 'https://webmapadmin.azurewebsites.net/api/admin/reports?status' + this.state.status + '&type=' + this.state.type;
     
    axios.get(reportQuery, { headers: { 'Authorization': bearer}})
      .then(res => {
        console.log(res);
        this.setState({ mapData: res.data, spinning: false, });
      }).catch(err => {
        this.setState({ spinning: false, });
      });
  }
  deleteReport = (reportId, locationId) => {
    this.setState({ spinning: true, });
    let bearer = this.getAccessToken();
    let reportQuery = 'https://webmapadmin.azurewebsites.net/api/admin/report?reportid=' + reportId + '&locationid=' + locationId;

    axios.delete(reportQuery, { headers: { 'Authorization': bearer } })
      .then(res => {
        this.loadReportData();
        this.setState({ spinning: false, });
      }).catch(err => {
        console.log(err);
        this.loadReportData();
        this.setState({ spinning: false, });
      });
  }
  approveReport = (reportId, locationId, verified) => {
    this.setState({ spinning: true, });
    let bearer = this.getAccessToken();
    let reportQuery = 'https://webmapadmin.azurewebsites.net/api/admin/status?reportid=' + reportId + '&locationid=' + locationId + '&approved=true' + '&verified=' + verified;
    axios.patch(reportQuery, { headers: { 'Authorization': bearer } })
      .then(res => {
        this.loadReportData();
        this.setState({ spinning: false, });
      }).catch(err => {
        console.log(err);
        this.loadReportData();
        this.setState({ spinning: false, });
      });
  }
  unApproveReport = (reportId, locationId, verified) => {
    this.setState({ spinning: true, });
    let bearer = this.getAccessToken();
    let reportQuery = 'https://webmapadmin.azurewebsites.net/api/admin/status?reportid=' + reportId + '&locationid=' + locationId + '&approved=false' + '&verified=' + verified;
    axios.patch(reportQuery, { headers: { 'Authorization': bearer } })
      .then(res => {
        this.loadReportData();
        this.setState({ spinning: false, });
      }).catch(err => {
        console.log(err);
        this.loadReportData();
        this.setState({ spinning: false, });
      });
  }

  verifyReport = (reportId, locationId, approved) => {
    this.setState({ spinning: true, });
    let bearer = this.getAccessToken();
    let reportQuery = 'https://webmapadmin.azurewebsites.net/api/admin/status?reportid=' + reportId + '&locationid=' + locationId +  '&approved=' + approved+ '&verified=true';
    axios.patch(reportQuery, { headers: { 'Authorization': bearer } })
      .then(res => {
        this.loadReportData();
        this.setState({ spinning: false, });
      }).catch(err => {
        console.log(err);
        this.loadReportData();
        this.setState({ spinning: false, });
      });
  }
  unVerifyReport = (reportId, locationId, approved) => {
    this.setState({ spinning: true, });
    let bearer = this.getAccessToken();
    let reportQuery = 'https://webmapadmin.azurewebsites.net/api/admin/status?reportid=' + reportId + '&locationid=' + locationId + '&approved=' + approved + '&verified=false';
    axios.patch(reportQuery, { headers: { 'Authorization': bearer } })
      .then(res => {
        this.loadReportData();
        this.setState({ spinning: false, });
      }).catch(err => {
        console.log(err);
        this.loadReportData();
        this.setState({ spinning: false, });
      });
  }


  loadReport = (reportId, locationId, reportHeader) => {
    this.setState({ spinning: true, });
    let bearer = this.getAccessToken();
    let reportQuery = 'https://webmapadmin.azurewebsites.net/api/admin/report?reportId=' + reportId + '&locationId=' + locationId;
    axios.get(reportQuery, { headers: { 'Authorization': bearer } })
      .then(res => {
        let reportDetails = res.data.data.sort((a, b) => (a.seqNo < b.seqNo) && (a.questionText < b.questionText))
        this.setState({ reportLocation: res.data.location, reportHeader: reportHeader, reportDetails: reportDetails, spinning: false, showReport: true });
      }).catch(err => {
        this.setState({ spinning: false, });
      });
  }

  closeReport = () => {
    this.setState({ showReport: false, showLocationModal: false, });
  };


  render() {
    const { spinning, showReport, mapData, reportLocation, reportHeader, reportDetails } = this.state;

    let ReportWindow = null;
    if (showReport === true)
      ReportWindow = <Modal
        title={null}
        visible={showReport}
        onCancel={this.closeReport}
        width={768}
        footer={null}
        maskClosable={false}
        zIndex={9999999}
        style={{ top: 56 }}
      >
        <Row style={{ marginBottom: '8px' }}>
          <Label caption={reportHeader.locationName} labelType='H5' block />
        </Row>
        <Row >
          <Col span={12}>
            <Label caption={reportLocation.address.unit ? reportLocation.address.unit + ' - ' + reportLocation.address.streetAddress : reportLocation.address.streetAddress} labelType='Body2' block />
            <Label caption={reportLocation.address.city + ',' + reportLocation.address.region + ' ' + reportLocation.address.postalCode} labelType='Body2' block />
            <Label caption='Applies to: ' labelType='Body1'  style={{ marginTop: '16px', paddingRight:'4px' }}/>
            {reportHeader.mobility === true ? <Label caption=' Mobility' labelType='Body2' style={{ paddingRight: '4px' }} /> : null}
            {reportHeader.hearing === true ? <Label caption=' Hearing' labelType='Body2' style={{ paddingRight: '4px' }} /> : null}
            {reportHeader.vision === true ? <Label caption=' Vision' labelType='Body2'  />:null}
          </Col>
          <Col span={12}>
            <Label caption={'Submitted by ' + reportHeader.type } labelType='Body1' block />
            <Label caption={reportHeader.contactFirstName + ' ' + reportHeader.contactLastName} labelType='Body2' block />
            <a href={'mailto:' + reportHeader.contactEmail} > <Label caption={reportHeader.contactEmail} labelType='Body2' block /></a>
            <Label caption={reportHeader.contactNumber} labelType='Body2' block />
          </Col>
        </Row>
        <Row style={{ marginTop: '16px', borderBottom:'1px solid black', marginBottom:'0px' }}>
          <Label caption='Accessibility Report' labelType='H6' block />
        </Row>
        <div style={{ marginBottom: '16px', overflowY: 'scroll', maxHeight: '500px', paddingTop: '16px', paddingBottom: '48px' }}>
        {reportDetails.map(question => {

          let answer = 'No';
          if (question.type ==='feature' && question.score ==='1')
            {answer = 'Yes';}
          if (question.type === 'procon' && question.score === '1')
          { answer = 'Yes'; }
          if (question.type === 'rating')
            {
              let score = question.score * 100;
              answer = score.toString() + '%';
             }

          return (
          <Row style={{ paddingBottom: '4px', marginBottom: '8px', marginTop: '0px'}} key={question.id}>
            <Col span={4}>
              {question.type === 'feature' ? <Label caption={question.stepName} key={question.id + 'F'} labelType= 'Body1' style={{ marginLeft: '0px', marginBottom: '8px' }} />:null}
            </Col>
            <Col span={16} offset={1}>
              <Label caption={question.questionText} key={question.id + 'Q'} labelType= 'Body2'  style={{ marginLeft: '0px', marginBottom:  '8px'  }} />
            </Col>
            <Col span={2} offset={1}>
              <Label caption={answer} key={question.id + 'A'} labelType='Body1' />
            </Col>
          </Row>
         
          )
        })}
        </div>
        <Row  >
           <Col span={24}>
             <Button size='large' type='default' onClick={() => this.closeReport()} style={{ float: 'right' }}>Close</Button>
          </Col>
        </Row>
      </Modal>;

    const columns = [
      {
        title: 'Submitted',
        dataIndex: 'dateCreated',
        key: 'dateCreated',
        align: 'center',
        width: 100,
        render: (text, record) => (<span>{text.substring(0, 10)}</span>)
      },
      {
        title: 'Location',
        dataIndex: 'locationName',
        key: 'locationName',
        render: (text, record) => (<span>{record.locationName}</span>)
      },
      {
        title: 'Reported By',
        dataIndex: 'contactFirstName',
        key: 'reportedBy',
        width: 200,
        render: (text, record) => (<span>{record.contactFirstName} {record.contactLastName}</span>)
      },

      {
        title: 'Report Type',
        dataIndex: 'type',
        key: 'type',
        width: 150,
        align: 'center',
        filters: [
          {
            text: 'User',
            value: 'user',
          },
          {
            text: 'Location',
            value: 'business',
          },
        ],
        onFilter: (value, record) => record.type.indexOf(value) === 0,
        render: (text, record) => (<span>{record.type === 'user' ? 'User' : 'Location'}</span>)
      },
      {
        title: 'Report Status',
        dataIndex: 'approved',
        key: 'approved',
        width: 150,
        align: 'center',
        filters: [
          {
            text: 'Approved Report',
            value: 1,
          },
          {
            text: 'Unapproved Report',
            value: 2,
          },
          {
            text: 'Submitted',
            value: 3,
          },
        ],
        onFilter: (value, record) => ((value === 1 && record.approved === true && record.type === 'business') ||
          (value === 2 && record.approved === false && record.type === 'business') || (value === 3 && record.approved === true && record.type !== 'business')),
        render: (text, record) => (<span>{record.type !== 'user' && text === true ? 'Approved' : 'Submitted'}</span>)
      },
      {
        title: 'Options',
        dataIndex: 'options',
        key: 'options',
        render: (text, record) => (<span>
          <Button size='large' type='link' onClick={() => this.loadReport(record.reportId, record.locationId, record)} >View</Button>
          {record.type === 'business' && record.approved === false ?
            <Button size='large' type='link' onClick={() => this.approveReport(record.reportId, record.locationId, record.verified)} >Approve</Button> : null}
          {record.type === 'business' && record.approved === true ?
            <Button size='large' type='link' onClick={() => this.unApproveReport(record.reportId, record.locationId, record.verified)} >Un-Approve</Button> : null}
          {record.type === 'business' && record.verified === false ?
            <Button size='large' type='link' onClick={() => this.verifyReport(record.reportId, record.locationId, record.approved)} >Verify</Button> : null}
          {record.type === 'business' && record.verified === true ?
            <Button size='large' type='link' onClick={() => this.unVerifyReport(record.reportId, record.locationId, record.approved)} >Un-Verify</Button> : null}
          <Popconfirm title='Are you sure you want to delete this report?' onConfirm={() => this.deleteReport(record.reportId, record.locationId)} > <Button size='large' type='link' >Delete</Button></Popconfirm>
        </span>)
      },
    ];
    return (
      <Spin spinning={spinning} tip='Loading accessibility reports...'>
        <Label labelType='H6' caption='Web Map Administration' style={{marginLeft:'16px'}}/>
        <Button size='large' type='primary' onClick={() => this.loadReportData()} style={{ float: 'right', marginRight: '16px' }}>Refresh List</Button>
        <Table dataSource={mapData} columns={columns} pagination ={{hideOnSinglePage: true}} style={{ padding: '16px', height: window.innerHeight - 256 }} bordered size='small' />;
        {ReportWindow}
      </Spin>
    )
  };
}

export default WebMap
