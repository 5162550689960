import './App.css';
import React, { useState } from 'react';
import Admin from './Components/WebMap';
import MapHeader from './Components/MapHeader';
import MapFooter from './Components/MapFooter';
import MobileLayout from './MobileLayout/MobileLayout'
import { AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import { ProfileData } from "./Components/ProfileData";

function ProfileContent() {
   const [graphData] = useState(null);
    return (
     <>
      {graphData ?
        <ProfileData graphData={graphData} />
         :null
      }
    </>
  );
};
function Logout() {
  console.log('logout');

}

function App() {
  let path = window.location.pathname.split('/').filter(each => !!each);
  if(path[0] ==='logout')
    Logout();

  return (
    window.innerWidth < 768 ?
      <MobileLayout>
        <AuthenticatedTemplate>
          <ProfileContent />
          <Admin  />
        </AuthenticatedTemplate>
        <UnauthenticatedTemplate>
        
          <div style={{
            position: 'absolute',
            left: '16px',
            right: '0px',
            top: '56px',
            overflow: 'auto',
            zIndex: 1
          }}>
            You must sign in to manage the Web Map Accessibility information!
          </div>
        </UnauthenticatedTemplate>
      </MobileLayout>
      : <div>
        <MapHeader />
        <AuthenticatedTemplate>
          <ProfileContent />
          <React.Fragment>
            <div style={{
              position: 'absolute',
              left: '0px',
              right: '0px',
              top: '144px',
              overflow: 'auto',
              zIndex: 1
            }}>
              <Admin />
            </div>
            <div style={{
              position: 'absolute',
              left: '0px',
              right: '0px',
              bottom: '0px',
              overflow: 'auto',
              zIndex: 2
            }}>
              <MapFooter />

            </div>
          </React.Fragment>
        </AuthenticatedTemplate>
        <UnauthenticatedTemplate>
          <div style={{
              position: 'absolute',
              left: '16px',
              right: '0px',
              top: '144px',
              overflow: 'auto',
              zIndex: 1
            }}>
            You must sign in to manage the Web Map Accessibility information!
               </div>
        </UnauthenticatedTemplate>
      </div >



  );
}

export default App;
